.mainpage {
    display: flex;
    flex-direction: row;
    /* background-color: yellow; */
    width: 100vw;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: black;
  }
  
  .leftOut{
    /* background-color: red; */
    /* width: 16%; */
    overflow: hidden;
  }
  .rightOut{
    /* background-color: blue; */
    /* width: 84%; */
    
    overflow: hidden;
  }