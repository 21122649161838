.leftSection {
    background-color: var(--col1);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.newChat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 10%;
}

.text1{
    color: var(--textcol);
    font-size: 20px;
    cursor: pointer;
}

.newChat div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.newChat div img{
    border-radius: 50%;
}
.newChat svg {
    width: 30px;
    height: 30px;
    color: var(--textcol);
}

.allChats{
    height: 70%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    align-items: start;
    gap: 20px;
    padding: 0px 20px;
}

.chat:hover .text1 {
    /* color: rgb(38, 116, 128); */
    color: var(--chat-hover);
}

.highlighted {
    /* color:#1f9389; */
    color: var(--chat-highlighted);
}

.darkModeButton {
    color: var(--textcol);
    background: var(--col1);
    border: none;
    text-align: start;
    margin-left: 12%;
}
  
